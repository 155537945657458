import { createRouter, createWebHistory } from '@ionic/vue-router';

const routes = [
  {
    path: '/',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/home',
    component: () => import('../views/HomePage.vue')
  },
  {
    path: '/contact',
    component: () => import('../views/Contacts/SearchContact.vue')
  },
  {
    path: '/location',
    component: () => import('../views/LocationPage.vue')
  },
  {
    path: '/selfServiceRequest',
    component: () => import('../views/SelfServiceRequest.vue')
  },
  {
    path: '/jobcard/search',
    component: () => import('../views/JobCards/SearchJobCards.vue')
  },
  {
    path: '/jobcard/view',
    component: () => import('../views/JobCards/ViewJobCard.vue')
  },
  {
    path: '/toolboxTalks',
    component: () => import('../views/ToolboxTalks/ToolboxTalkSearch.vue')
  },
  {
    path: '/checklist/complete',
    component: () => import('../views/ToolboxTalks/ToolboxTalkDisplay.vue')
  },
  {
    path: '/loanAccount',
    component: () => import('../views/loanAccount/ViewLoanAccount.vue')
  },
  {
    path: '/checklists',
    component: () => import('../views/Checklists/UserChecklists.vue')
  },
  {
    path: '/user/checklists/complete',
    component: () => import('../views/Checklists/CompleteUserChecklists.vue')
  }, 
  {
    path: '/ppeRequest',
  component: () => import('../views/PersonalProtectiveEquipment/PersonalProtectiveEquipment.vue')
  },
  {
    path: '/timesheets',
    component: () => import('../views/Timesheets/TimeSheets.vue')
  },
  {
    path: '/timesheets/add-list',
    component: () => import('../views/Timesheets/TimeSheetsManage.vue')
  },
  {
    path: '/timesheets/submit-list',
    component: () => import('../views/Timesheets/TimeSheetsSubmit.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
