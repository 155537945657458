<template>
  <ion-app>
    <ion-split-pane content-id="main-content" v-if="state !== 'initializing'">
      <ion-menu content-id="main-content" type="overlay" side="end" swipeGesture="false">
        <ion-content>
          <ion-list id="inbox-list">
            <ion-list-header>{{ name }}</ion-list-header>
            <ion-note>{{ email }}</ion-note>

            <ion-menu-toggle auto-hide="false" v-for="(p, i) in appPages" :key="i">
              <ion-item v-if="hasAccess(p.accessname)" @click="navigateToPage(p.url)" :class="{ selected: isSelected(p.url) }" lines="none"
                detail="false" class="hydrated">
                <ion-icon :ios="p.iosIcon" :md="p.mdIcon" class="mr-2"></ion-icon>
                <ion-label>{{ p.title }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>
          <p style="color: black !important;">Version Number: {{ $config.app_version }}</p>
          <br>
          <p style="color: black !important;">Developed by CWARE</p>
        </ion-content>
      </ion-menu>

      <ion-router-outlet id="main-content"></ion-router-outlet>
    </ion-split-pane>
  </ion-app>
</template>

<script>
import defaultService from './services/defaultService.js';
import {
  IonApp,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonRouterOutlet,
  IonSplitPane,
} from '@ionic/vue';
import { onBeforeRouteUpdate, useRoute } from 'vue-router';
import {
  chatbubblesOutline,
  clipboardOutline,
  cubeOutline,
  homeOutline,
  listOutline,
  callOutline,
  flagOutline,
  archiveOutline,
  archiveSharp,
  bookmarkOutline,
  bookmarkSharp,
  heartOutline,
  heartSharp,
  mailOutline,
  mailSharp,
  paperPlaneOutline,
  paperPlaneSharp,
  warningOutline,
  warningSharp,
  shieldOutline,
  timeOutline,
} from 'ionicons/icons';
import router from './router';
import { reactive, watch } from 'vue';

export default {
  name: 'App',
  components: {
    IonApp,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonNote,
    IonRouterOutlet,
    IonSplitPane,
  },

  setup() {
    const pageState = reactive({
      selectedIndex: 0,
    });
    const appPages = [
      {
        title: 'Home',
        url: '/home',
        iosIcon: homeOutline,
        mdIcon: homeOutline,
        selected: true,
      },
      {
        title: 'Self Service Request',
        url: '/selfServiceRequest',
        iosIcon: flagOutline,
        mdIcon: flagOutline,
        accessname: 'Self Service Request'
      },
      {
        title: 'Job Cards',
        url: '/jobcard/search',
        iosIcon: clipboardOutline,
        mdIcon: clipboardOutline,
        accessname: 'Job Cards'
      },
      {
        title: 'Contacts',
        url: '/contact',
        iosIcon: callOutline,
        mdIcon: callOutline,
        accessname: 'Mobile.Contacts'
      },
      {
        title: 'Checklists',
        url: '/user/checklists/complete',
        iosIcon: listOutline,
        mdIcon: listOutline,
        accessname: 'Complete Checklist'
      },
      {
        title: 'Toolbox Talks',
        url: '/toolboxTalks',
        iosIcon: chatbubblesOutline,
        mdIcon: chatbubblesOutline,
        accessname: 'Complete Toolbox Talk'
      },
      {
        title: 'Loan Account',
        url: '/loanAccount',
        iosIcon: cubeOutline,
        mdIcon: cubeOutline,
        accessname: 'Mobile.Loan Account'
      },
      {
        title: 'PPE Request',
        url: '/ppeRequest',
        iosIcon: shieldOutline,
        mdIcon: shieldOutline,
        accessname: ''
      },
      {
        title: 'Time Sheets',
        url: '/timesheets',
        iosIcon: timeOutline,
        mdIcon: timeOutline,
        accessname: 'Timesheet Submission'
      },
      {
        title: 'Log out',
        url: '/',
        iosIcon: archiveSharp,
        mdIcon: archiveSharp,
     
      },
    ];

    const route = useRoute();

    watch(() => route.path, (newPath) => {
      pageState.selectedIndex = appPages.findIndex((page) => page.url === newPath);
    });

    onBeforeRouteUpdate((to, from, next) => {
      pageState.selectedIndex = appPages.findIndex((page) => page.url === to.path);
      next();
    });

    return {
      pageState,
      appPages,
      isSelected(url) {
        return route.path === url ? 'selected' : '';
      }
    };
  },

  data() {
    return {
      state: 'initializing',
      clipboardOutline,
      archiveOutline,
      archiveSharp,
      bookmarkOutline,
      bookmarkSharp,
      heartOutline,
      heartSharp,
      mailOutline,
      mailSharp,
      paperPlaneOutline,
      cubeOutline,
      paperPlaneSharp,
      shieldOutline,
      callOutline,
      warningOutline,
      warningSharp,
      flagOutline,
      chatbubblesOutline,
      timeOutline,
      email: null,
      name: null,
      versionName: 'QA v1.1',
      rights: []
    };
  },

  created() {
    defaultService.setCurrentLocation();
    this.name = localStorage.getItem('user');
    this.email = localStorage.getItem('userEmail');
    this.rights = JSON.parse(localStorage.getItem('screenList'))

    this.state = 'show';

    const selectedMenuItemIndex = localStorage.getItem('selectedMenuItemIndex');
    if (selectedMenuItemIndex !== null) {
      this.pageState.selectedIndex = parseInt(selectedMenuItemIndex);
    }

    router.beforeEach((to, from, next) => {
      console.log('Navigating to', to.path);
      this.name = localStorage.getItem('user');
      this.email = localStorage.getItem('userEmail');
      this.rights = JSON.parse(localStorage.getItem('screenList'));
      next();
    });
  },
  methods: {
    navigateToPage(url) {
      const newIndex = this.appPages.findIndex((page) => page.url === url);
      this.pageState.selectedIndex = newIndex;
      this.$router.push(url);
    },
    hasAccess(screen){

      //console.log('hasacess check:' + screen)

      if(screen == null || screen == '')
      {
        return true
      }
                if (this.rights != null){
                    for (let i = 0; i < this.rights.length; i++) {
                        const element = this.rights[i];
                      
                        if (element.toLowerCase() === screen.toLowerCase()) {
                          //console.log("Element: " + element.toLowerCase() + ":" + screen)
                            return true
                        }
                        // else
                        // {
                        //   console.log("Element: " + element.toLowerCase() + ":" + screen)
                        // }
                        //if(screen != "")
                        //return true
                    }
                }
                return false
            }
  }
};
</script>