import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { IonicVue, isPlatform } from '@ionic/vue';

import '@ionic/vue/css/core.css';
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

import './theme/variables.css';
import './theme/basic.scss';
import './theme/style.scss';

const app = createApp(App);

app.mixin({
  created() {
    this.showCameraIcon = isPlatform('ios') ? false : true;
  }
});

const initializeApp = async () => {
  try {
    const response = await fetch('/config.json');
    const config = await response.json();
    app.config.globalProperties.$config = config;

    app.use(IonicVue);
    app.use(router);

    await router.isReady();
    app.mount('#app');
  } catch (error) {
    console.error('Failed to load config.json', error);
  }
};

initializeApp();