/* eslint-disable */

var generalService = {
    location: null,
    

    setCurrentLocation(){
        if(!("geolocation" in navigator)) {
            console.log('laskaslkls', navigator)
            return;
        }

        navigator.geolocation.getCurrentPosition(pos => {
            this.gettingLocation = false;
            this.location = pos;
          }, err => {
            this.gettingLocation = false;
            console.log(err.message)
          })
    }
}

export default generalService